import { index, store, update, updatePrice, destroy } from '../../services/variants/variants'
import Vue from 'vue'
export const variants = {

  namespaced: 'variants',

  state: {
    data: {},
    pagination: {},
    details: {},
    loading: true,
    error: null
  },

  actions: {

    index ({ state, commit }, { page, name, product }) {
      commit('setLoading', true)
      index(page, product, name)
        .then(response => {
          commit('setData', response.data.variants)
          commit('setPagination', response.data.variants)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    store ({ state, commit, dispatch }, { product, data }) {
      commit('setLoading', true)
      store(product, data)
        .then(response => {
          commit('setDetails', response.data.variant)
          commit('setLoading', false)
          Vue.toasted.success('Variação cadastrada com sucesso!')
          dispatch('index', { page: 1, product })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao cadastrar a Variação!')
        })
    },

    update ({ state, commit, dispatch }, { product, id, data }) {
      commit('setLoading', true)
      update(product, id, data)
        .then(response => {
          commit('setDetails', response.data.variant)
          commit('setLoading', false)
          Vue.toasted.success('Variação editada com sucesso!')
          dispatch('index', { page: 1, product })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao editar a Variação!')
        })
    },

    updatePrice ({ state, commit, dispatch }, { product, data }) {
      commit('setLoading', true)
      updatePrice(product, data)
        .then(() => {
          commit('setLoading', false)
          Vue.toasted.success('Preços alterados com sucesso!')
          dispatch('index', { page: 1, product: product })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao editar a Variação!')
        })
    },

    destroy ({ state, commit, dispatch }, { product, id }) {
      commit('setLoading', true)
      destroy(product, id)
        .then(response => {
          commit('setDetails', response.data.variant)
          commit('setLoading', false)
          Vue.toasted.success('Variação excluída com sucesso!')
          dispatch('index', { page: 1, product })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao excluir a Variação!')
        })
    }

  },

  mutations: {

    setData (state, variants) {
      state.data = variants
    },

    setDetails (state, variant) {
      state.details = variant
    },

    setLoading (state, status) {
      state.loading = status
    },

    setPagination (state, paginate) {
      state.pagination = paginate
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
