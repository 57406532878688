<template>
  <section>
  <form @submit.prevent="save()">
    <div class="card mb-5">
      <div class="card-header bg-transparent">
        <h6 class=" mb-0">Informações principais</h6>
      </div>
      <div class="card-body pb-3">
        <div class="row">
          <div class="col-md-5 pb-3">
            <label for="title" class="form-control-label">Nome: <span class="text-danger">*</span></label>
            <input v-model="data.title" class="form-control" type="text" placeholder="Digite aqui.." id="title">
            <span class="text-danger mt-1" v-if="errors.title">Este campo é obrigatório</span>
          </div>
          <div class="col-md-3 pb-3">
            <label for="slug" class="form-control-label">Slug:</label>
            <input type="text" v-model="data.slug" id="slug" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-2 pb-3">
            <label for="relevance" class="form-control-label">Relevância:</label>
            <input type="number" v-model="data.relevance" id="relevance" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-2 pb-3">
              <label for="status" class="form-control-label">Status: <span class="text-danger">*</span></label>
              <select id="status" class="form-control slt-opt" v-model="data.status">
                  <option :value="null">Selecione</option>
                  <option :value="1">Ativo</option>
                  <option :value="0">Inativo</option>
              </select>
              <span class="text-danger mt-1" v-if="errors.status">Este campo é obrigatório</span>
          </div>
          <div class="col-12 pb-3">
            <label for="short_description" class="form-control-label">Descrição curta: <span class="text-danger">*</span></label>
            <textarea class="form-control" v-model="data.short_description" type="text" placeholder="Digite aqui.." id="short_description"></textarea>
            <span class="text-danger mt-1" v-if="errors.short_description">Este campo é obrigatório</span>
          </div>
          <div class="col-md-12 pb-3">
            <label for="description" class="form-control-label">Descrição:</label>
            <textarea class="form-control" v-model="data.description" type="text" placeholder="Digite aqui.." id="description" rows="8"></textarea>
            <span class="text-danger mt-1" v-if="errors.description">Este campo é obrigatório</span>
          </div>
          <div class="col-md-3 pb-3">
            <label for="brand" class="form-control-label">Marca:</label>
            <input type="text" v-model="data.brand" id="brand" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-3 pb-3">
            <label for="condition" class="form-control-label">Condição:</label>
            <select id="condition" class="form-control" v-model="data.condition">
                  <option :value="null">Selecione</option>
                  <option :value="'Novo'">Novo</option>
                  <option :value="'Usado'">Usado</option>
                  <option :value="'Não Especificado'">Não Especificado</option>
              </select>
          </div>
          <div class="col-md-3 pb-3">
            <label for="installments" class="form-control-label">Número de Parcelas:</label>
            <select id="installments" class="form-control slt-opt" v-model="data.installments">
              <option :value="null">Selecione</option>
              <option :value="1">1</option>
              <option :value="2">2</option>
              <option :value="3">3</option>
              <option :value="4">4</option>
              <option :value="5">5</option>
              <option :value="6">6</option>
              <option :value="7">7</option>
              <option :value="8">8</option>
              <option :value="9">9</option>
              <option :value="10">10</option>
              <option :value="11">11</option>
              <option :value="12">12</option>
            </select>
          </div>
          <div class="col-md-3 pb-3">
            <label for="warranty" class="form-control-label">Garantia:</label>
            <input type="text" v-model="data.warranty" id="warranty" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-6 pb-3">
            <label for="type" class="form-control-label">Tipo de Produto: <span class="text-danger">*</span></label>
            <select id="type" class="form-control slt-opt" v-model="data.type">
                <option :value="null">Selecione</option>
                <option :value="1">Produto Simples</option>
                <option :value="2">Produto com Variações</option>
                <option :value="3">Kit Simples</option>
                <option :value="4">Kit com Variações</option>
            </select>
            <span class="text-danger mt-1" v-if="errors.type">Este campo é obrigatório</span>
          </div>
          <div class="col-md-3 pb-3">
            <label for="type_product_id" class="form-control-label">ID do tipo de produto:</label>
            <input type="text" v-model="data.type_product_id" id="type_product_id" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-3 pb-3">
            <label for="group_id" class="form-control-label">ID do grupo:</label>
            <input type="text" v-model="data.group_id" id="group_id" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-12 pb-3">
            <label for="vsl_title" class="form-control-label">Título para VSL:</label>
            <input type="text" v-model="data.vsl_title" id="vsl_title" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-12 pb-3">
            <label for="youtube_video_url" class="form-control-label">Url vídeo no youtube:</label>
            <input type="text" v-model="data.youtube_video_url" id="youtube_video_url" placeholder="Digite aqui..." class="form-control">
          </div>
        </div>
      </div>
    </div>
    <IndexVariants v-if="data.type == 2 && data.id" :id="data.id"/>
    <div v-if="data.type == 3 || data.type == 4" class="card my-5">
      <div class="card-header bg-transparent">
        <div class="row align-items-center">
          <div class="col-md">
            <h6 class="mb-0">Produtos do Kit</h6>
          </div>
          <div class="col-md text-right">
            <button class="btn btn-primary" type="button" @click="data.products.push({product_id: null, quantity: 0})">+</button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row" v-for="(product, i) in data.products" :key="'product'+i">
          <div class="col-md-9 pb-3">
            <label for="split_product_id" class="form-control-label">Produto:</label>
            <select id="status" class="form-control slt-opt" v-model="product.product_id">
                <option :value="null">Selecione</option>
                <option v-for="param_product in products" :key="param_product.id" :value="param_product.id">{{ param_product.title }}</option>
            </select>
          </div>
          <div class="col-md-3">
            <label for="product_quantity" class="form-control-label w-100">Quantidade:</label>
            <input type="number" v-model="product.quantity" id="product_quantity" placeholder="Digite aqui..." class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div v-if="data.type == 4" class="card my-5">
      <div class="card-header bg-transparent">
        <div class="row align-items-center">
          <div class="col-md">
            <h6 class="mb-0">Cores do Kit</h6>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-start">
          <input type="checkbox" name="all_products">
          <small class="ml-2 ws-nowrap lh-15">Selecionar Todos</small>
        </div>
        <div class="row mt-3">
          <div class="col-md-4" v-for="(color, ic) in colors" :key="'color'+ic">
            <div class="d-flex align-items-center mb-2">
              <input type="checkbox" :id="'color'+ color.id" :value="color" v-model="data.colors"><label class="my-0 py-0 pointer" :for="'color'+ color.id"><small class="ml-2 ws-nowrap lh-15">{{ color.description }}</small></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="data.type == 4" class="card my-5">
      <div class="card-header bg-transparent">
        <div class="row align-items-center">
          <div class="col-md">
            <h6 class="mb-0">Tamanhos do Kit</h6>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-start">
          <input type="checkbox" name="all_products">
          <small class="ml-2 ws-nowrap lh-15">Selecionar Todos</small>
        </div>
        <div class="row mt-3">
          <div class="col-md-4" v-for="(size, ic) in sizes" :key="'size'+ic">
            <div class="d-flex align-items-center mb-2">
              <input type="checkbox" :id="'size'+ size.id" :value="size" v-model="data.sizes"><label class="my-0 py-0 pointer" :for="'size'+ size.id"><small class="ml-2 ws-nowrap lh-15">{{ size.description }}</small></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="data.type == 4" class="card my-5">
      <div class="card-header bg-transparent">
        <div class="row align-items-center">
          <div class="col-md">
            <h6 class="mb-0">Materiais do Kit</h6>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-start">
          <input type="checkbox" name="all_products">
          <small class="ml-2 ws-nowrap lh-15">Selecionar Todos</small>
        </div>
        <div class="row mt-3">
          <div class="col-md-4" v-for="(material, ic) in materials" :key="'material'+ic">
            <div class="d-flex align-items-center mb-2">
              <input type="checkbox" :id="'material'+ material.id" :value="material" v-model="data.materials"><label class="my-0 py-0 pointer" :for="'material'+ material.id"><small class="ml-2 ws-nowrap lh-15">{{ material.description }}</small></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card my-5">
      <div class="card-header bg-transparent">
        <h6 class="mb-0">Fiscal</h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 pb-3">
              <label for="gtin_code" class="form-control-label">GTIN:</label>
              <input type="text" v-model="data.gtin_code" id="gtin_code" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-4 pb-3">
              <label for="gtin_package" class="form-control-label">GTIN Embalagem:</label>
              <input type="text" id="gtin_package" v-model="data.gtin_package" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-4 pb-3">
              <label for="fiscal_cest_code" class="form-control-label">Código Cest:</label>
              <input type="text" id="fiscal_cest_code" v-model="data.fiscal_cest_code" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-4">
              <label for="fiscal_origin_code" class="form-control-label">Origem:</label>
              <input type="text" id="fiscal_origin_code" v-model="data.fiscal_origin_code" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-4">
              <label for="fiscal_ncm_code" class="form-control-label">Código NCM:</label>
              <input type="text" id="fiscal_ncm_code" v-model="data.fiscal_ncm_code" placeholder="Digite aqui..." class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div class="card my-5">
      <div class="card-header bg-transparent">
        <h6 class="mb-0">Códigos e estoque</h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3 pb-3">
            <label for="unit" class="form-control-label">Unidade:</label>
            <input type="text" v-model="data.unit" id="unit" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-3 pb-3">
            <label for="validate_date" class="form-control-label">Data de validade:</label>
            <input type="date" v-model="data.validate_date" id="validate_date" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-3 pb-3">
            <label for="stock_quantity" class="form-control-label">Quantidade no estoque:</label>
            <input type="text" v-model="data.stock_quantity" id="stock_quantity" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-3 pb-3">
            <label for="stock_waiting" class="form-control-label">Aguardando estoque:</label>
            <input type="text" v-model="data.stock_waiting" id="stock_waiting" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-3 pb-3">
            <label for="stock_min" class="form-control-label">Estoque mínimo:</label>
            <input type="text" v-model="data.stock_min" id="stock_min" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-3 pb-3">
            <label for="stock_max" class="form-control-label">Estoque máximo:</label>
            <input type="text" v-model="data.stock_max" id="stock_max" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-3 pb-3">
            <label for="stock_location" class="form-control-label">Localização de estoque:</label>
            <input type="text" v-model="data.stock_location" id="stock_location" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-3 pb-3">
            <label for="sku" class="form-control-label">sku:</label>
            <input type="text" v-model="data.sku" id="sku" placeholder="Digite aqui..." class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div class="card my-5">
      <div class="card-header bg-transparent">
        <h6 class="mb-0">Dimensões e Frete</h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 pb-3">
            <div class="input-group">
              <label for="width" class="form-control-label w-100">Largura:</label>
              <money v-bind="measure" id="width" v-model="data.width" class="form-control" aria-label="width" aria-describedby="width-addon"></money>
              <div class="input-group-append">
                <span class="input-group-text" id="width-addon">cm</span>
              </div>
            </div>
          </div>
          <div class="col-md-4 pb-3">
            <div class="input-group">
              <label for="height" class="form-control-label w-100">Altura:</label>
              <money v-bind="measure" type="text" id="height" v-model="data.height" class="form-control" aria-label="height" aria-describedby="height-addon"></money>
              <div class="input-group-append">
                <span class="input-group-text" id="height-addon">cm</span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="input-group">
              <label for="depth" class="form-control-label w-100">Comprimento:</label>
              <money id="depth" class="form-control" v-model="data.depth" v-bind="measure" aria-label="depth" aria-describedby="depth-addon"></money>
              <div class="input-group-append">
                <span class="input-group-text" id="depth-addon">cm</span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="input-group">
              <label for="weight" class="form-control-label w-100">Peso:</label>
              <money class="form-control weight" id="weight" v-model="data.weight" v-bind="measure"></money>
              <div class="input-group-append">
                <span class="input-group-text" id="weight-addon">g</span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <label for="free_shipping" class="form-control-label">Envio grátis:</label>
            <select id="free_shipping" class="form-control slt-opt" v-model="data.free_shipping">
              <option :value="null">Selecione</option>
              <option :value="1">Sim</option>
              <option :value="0">Não</option>
            </select>
          </div>
          <div class="col-md-4 pb-3">
            <label for="crossdocking" class="form-control-label">Cross Docking:</label>
            <input type="text" v-model="data.crossdocking" id="crossdocking" placeholder="Digite aqui..." class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div class="card my-5">
      <div class="card-header bg-transparent">
        <h6 class="mb-0">Preços</h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3 pb-3">
              <label for="status" class="form-control-label">Controle de Preço: <span class="text-danger">*</span></label>
              <select id="status" class="form-control slt-opt" v-model="data.price_platform">
                  <option :value="null">Selecione</option>
                  <option :value="1">Externo</option>
                  <option :value="2">Interno</option>
              </select>
              <span class="text-danger mt-1" v-if="errors.status">Este campo é obrigatório</span>
          </div>
          <div class="col-md-3">
            <div class="input-group">
              <label for="costprice" class="form-control-label w-100">Preço de custo:</label>
              <div class="input-group-prepend">
                <span class="input-group-text" id="costprice-addon">R$</span>
              </div>
              <money class="form-control weight" id="weight" v-model="data.costprice" v-bind="money"></money>
            </div>
          </div>
          <div class="col-md-3">
            <div class="input-group">
            <label for="price" class="form-control-label w-100">Preço:</label>
              <div class="input-group-prepend">
                <span class="input-group-text" id="price-addon">R$</span>
              </div>
              <money class="form-control weight" id="weight" v-model="data.price" v-bind="money"></money>
            </div>
          </div>
          <div class="col-md-3">
            <div class="input-group">
            <label for="saleprice" class="form-control-label w-100">Preço de venda:</label>
              <div class="input-group-prepend">
                <span class="input-group-text" id="saleprice-addon">R$</span>
              </div>
              <money class="form-control weight" id="weight" v-model="data.saleprice" v-bind="money"></money>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-5">
      <div class="card-header bg-transparent">
        <h6 class="mb-0">Imagens</h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="row align-items-center">
              <div class="col-md-6">
                <h3 class="text-white">Imagens</h3>
              </div>
              <div class="col-md-6 text-right">
                <button v-if="img" class="btn btn-warning text-uppercase px-3 py-3" @click="crop()">
                  CORTAR
                </button>
                <input v-else type="file" ref="file" class="form-control" @change="uploadImage($event)" accept="image/*">
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div v-for="(image, i) in data.images" :key="image.id" class="col-md-3 mt-3">
                    <div class="delete">
                      <img :src="image.link" class="w-100" alt="">
                      <button class="btn" @click="deleteImageInArray(i)">
                        <i class="fas fa-trash-alt pos text-danger"></i>
                      </button>
                    </div>
                  </div>
                  <div v-for="file in data.files" :key="file.id" class="col-md-3 mt-3">
                    <div class="delete">
                      <img :src="file.link" class="w-100" alt="">
                      <button class="btn" @click="deleteImageInArray(i)">
                        <i class="fas fa-trash-alt pos text-danger"></i>
                      </button>
                    </div>
                  </div>
                  <div v-if="img" class="col-md-3 mt-3">
                    <Cropper
                      :restrictions="pixelsRestriction"
                      :src="img"
                      ref="cropper"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-5">
      <div class="card-header bg-transparent">
        <h6 class="mb-0">Cupons</h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 pb-3">
            <button type="button" v-for="(coupon, i) in data.coupons" :key="coupon.id" @click="data.coupons.splice(i, 1)" class="btn btn-tag mr-3">
              {{ coupon.name ? coupon.name : coupon.description }} <i class="fas fa-plus ml-2"></i> <i class="fas fa-minus ml-2"></i>
            </button>
          </div>
          <div class="col-12">
            <label class="form-control-label">Selecione o Cupom: </label>
            <Dropdown
              :options="coupons"
              :disabled="false"
              @selected="addCoupon"
              :maxItem="10"
              placeholder="Vincular um cupom"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header bg-transparent">
        <h6 class="mb-0">Categories</h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 pb-3">
            <button type="button" v-for="(category, i) in data.categories" :key="category.id" @click="data.categories.splice(i, 1)" class="btn btn-tag mr-3">
              {{ category.name ? category.name : category.description }} <i class="fas fa-plus ml-2"></i> <i class="fas fa-minus ml-2"></i>
            </button>
          </div>
          <div class="col-12">
            <label class="form-control-label">Selecione a categoria: </label>
            <Dropdown
              :options="categories"
              :disabled="false"
              @selected="addCategory"
              :maxItem="10"
              placeholder="Vincular uma categoria"
            />
          </div>
        </div>
      </div>
    </div>
    <button type="submit" v-if="!img" class="btn btn-success mt-3">
      Salvar Alterações
    </button>
  </form>

    <!-- Register Modal -->
    <div class="modal fade" id="CreateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ (variant.id ? 'Editar Variação' : 'Cadastrar Variação')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <label class="form-control-label">Nome:</label>
                    <input type="text" class="form-control" v-model="variant.description" required>
                  </div>
                  <div class="col">
                    <label class="form-control-label">SKU:</label>
                    <input type="text" class="form-control" v-model="variant.sku_code">
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col">
                    <div class="input-group">
                    <label for="price" class="form-control-label w-100">Preço:</label>
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="price-addon">R$</span>
                      </div>
                      <money class="form-control weight" id="weight" v-model="variant.price" v-bind="money"></money>
                    </div>
                  </div>
                  <div class="col">
                    <label class="form-control-label">Quantidade:</label>
                    <input type="text" class="form-control" v-model="variant.quantity">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-success" @click="updateVariant()" v-if="variant.id">Editar Variação</button>
            <button type="button" class="btn btn-success" @click="addVariant()" v-else>Cadastrar Variação</button>
          </div>
        </div>
      </div>
    </div>

  </section>

</template>

<script>
import { mapActions, mapState } from 'vuex'
import Dropdown from 'vue-simple-search-dropdown'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import IndexVariants from './IndexVariants.vue'

export default {

  props: ['_product'],

  components: {
    Cropper,
    IndexVariants,
    Dropdown
  },

  watch: {
    _product () {
      this.data = {
        ...this.data,
        ...this._product,
        files: []
      }
    }
  },

  data () {
    return {
      data: {
        colors: [],
        sizes: [],
        materials: [],
        products: [],
        variants: [],
        categories: [],
        coupons: [],
        files: [],
        installments: null,
        type: null,
        status: null,
        free_shipping: null
      },
      price_parameters: {
        color_id: null,
        material_id: null,
        size_id: null,
        price: 0,
        saleprice: 0
      },
      errors: {},
      img: null,
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      },
      variant: {},
      destroy_variant: {},
      measure: {
        decimal: ',',
        thousands: '',
        precision: 2
      }
    }
  },

  computed: mapState({
    colors: state => state.params.data.colors,
    sizes: state => state.params.data.sizes,
    materials: state => state.params.data.materials,
    categories: state => state.params.data.categories,
    products: state => state.params.data.products,
    coupons: state => state.params.data.coupons,
    loading: state => state.params.loading
  }),

  methods: {

    ...mapActions('params', [
      'getCoupons',
      'getProducts',
      'getCategories',
      'getColors',
      'getSizes',
      'getMaterials'
    ]),

    pixelsRestriction ({ minWidth, minHeight, maxWidth, maxHeight, imageWidth, imageHeight }) {
      this.errorImage = null

      // se a imagem for menor que 200px
      if (imageHeight < 200 || imageWidth < 200) {
        this.errorImage = 'Essa imagem é muito pequena. Para melhor visualização do produto, opte por imagens com dimensões superiores a 600x600 px'
        return false

      // se a imagem for maior que 200px e menor que 600px
      } else if (imageHeight < 600 || imageWidth < 600) {
        this.errorImage = 'Dica: Para melhor visualização do produto, opte por imagens com dimensões superiores a 600px x 600px'
        return {
          minWidth: minWidth,
          minHeight: minHeight,
          maxWidth: Math.min(imageWidth, maxWidth),
          maxHeight: Math.min(imageHeight, maxHeight)
        }
      }
    },

    uploadImage (event) {
      // Reference to the DOM input element
      var input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.img = e.target.result
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
      }
    },

    crop () {
      const { coordinates, canvas } = this.$refs.cropper.getResult()
      this.coordinates = coordinates
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      this.data.files.push({
        id: Date.now(),
        link: canvas.toDataURL()
      })
      this.img = null
    },

    save () {
      if (this.data.id) {
        this.$emit('update', { id: this.$route.params.id, data: this.data })
      } else {
        this.$emit('store', this.data)
      }
    },

    deleteImageInArray (index) {
      this.data.images.splice(index, 1)
    },

    changeCategory (response) {
      if (response) {
        this.data.category_id = response.category_id
        this.data.categories = response.categories_title
      }
    },

    addCoupon (coupon) {
      const found = this.data.coupons.find(dataCoupon => {
        return dataCoupon.id === coupon.id
      })
      if (!found) {
        this.data.coupons.push(coupon)
      }
    },

    addCategory (category) {
      const found = this.data.categories.find(dataCategory => {
        return dataCategory.id === category.id
      })
      if (!found) {
        this.data.categories.push(category)
      }
    },

    openUpdateModal (variant) {
      this.variant = variant
      $('#CreateModal').modal()
    },

    openDeleteModal (variant) {
      this.destroy_variant = variant
      $('#openDeleteModal').modal()
    }

  },

  mounted () {
    this.data = {
      ...this.data,
      ...this._product,
      files: []
    }
    this.getCoupons({ page: 1 })
    this.getCategories({ page: 1 })
    this.getProducts()
    this.getColors()
    this.getSizes()
    this.getMaterials()
  }

}
</script>

<style>

  /* .products .form-control-label{
    color: #fff !important;
  } */

</style>
